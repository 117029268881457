<template>
  <div style="min-height: 100vh; ">
    <div class="header">
      <titleLink title="通知详情" :onClick="onClickBack"></titleLink>
      <div style="height:90vh;overflow-y:scroll;padding:0 .4rem;padding-bottom: 6rem;">
      <card style="margin-top:30px;">
        <template #content>
          <van-row style="text-align:center;">
            <p class="title_text">{{notification.notification.title}}</p>
          </van-row>
          <van-row style="margin-top:30px">
            <p v-html="notification.notification.content" style="font-size: 14px;
    color: #666;
    text-align: justify"></p>
          </van-row>
          <div v-if="notification.notification.file_url !== '' && notification.notification.file_url !== null">
            <van-divider dashed/>
            <div style="font-size: 15px;width: 100%;word-wrap: break-word;">
              附件： 
              <a :href="notification.notification.file_url" style="color:#395EE8" 
                v-if="notification.notification.file_url !== null">
                {{notification.notification.file_url}}
              </a>
            </div>
          </div>
          <van-row class="title_info_text">
            <van-col span="12" style="text-align:left">
              {{notification.notification.author}}
            </van-col>
            <van-col span="12" style="text-align:right">
              {{notification.notification.created_at}}
            </van-col>
          </van-row>
        </template>
      </card>
      </div>
      
    </div>
  </div>
</template>

<script>
import card from "@/views/components/Card";
import {mapGetters} from 'vuex'
import titleLink from "@/views/components/TitleLink"
export default {
  components: {
    card, titleLink
  },
  computed: {
    ...mapGetters('notification', {
      notification: 'getCurrentNotification'
    })
  },
  created() {
    this.$store.dispatch('notification/getNotificationById', this.$route.params.id)
  },
  methods: {
    onClickBack() {
      this.$router.push({path: '/notification/main', query: {type: '1,2'}})
    }
  }
}
</script>

<style>
.title_text {
  font-size: 16px;
  font-weight: bold;
}
.title_info_text {
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
  color: #999999;
}
.content_text {
  color: #666666;
  font-size: 14px;
}
</style>